import React, { useEffect, useState } from "react";
import searchicon from "../../Assets/images/searchicon.svg";
import { Form } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGrouplistAction, updatconversationwithgroupAction } from "../../redux/actions/authAction";
import InitialsAvatar from "react-initials-avatar";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function ChatSidebarComponent({ selectedgroup, isLoading, setIsLoading, setSelectedGroup, setGroupName, setArrFiles, setFiles, setGroupcreatorID }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();

  const [search, setSearch] = useState("");
  const [grouplistArr, setgrouplistArr] = useState([]);
  const [selectedGroupSideBar, setSelectedGroupSideBar] = useState();

  const queryRoomId = query.get("roomId");
  const currGroupId = query.get("id");
  const regex = new RegExp(search, "i");

  const grouplistarray = useSelector((state) => state.auth.grouplist);
  const grouplistFilteredarray = grouplistarray.filter((item) => regex.test(item?.groupID?.groupName));

  // useEffect(() => {
  //   if (currGroupId) return
  //   dispatch(getGrouplistAction()).then(function (data) {
  //     const grouplist = data?.payload[0];
  //     localStorage.getItem('id')
  //     localStorage.getItem('gname')
  //     if (localStorage.getItem('id') == undefined) {
  //       if (queryRoomId) {
  //         let roomDetail = data?.payload?.find((item) => item?.groupID?._id?.toString() == queryRoomId);
  //         setSelectedGroup(roomDetail?.groupID?._id)
  //         setSelectedGroupSideBar(roomDetail?.groupID?._id)
  //         setGroupcreatorID(roomDetail?.groupID?.creatorUserID)
  //         setGroupName(roomDetail?.groupID?.groupName)
  //         dispatch(updatconversationwithgroupAction({
  //           groupid: roomDetail?.groupID?._id
  //         }))
  //       } else {
  //         setSelectedGroup(grouplist?.groupID?._id)
  //         setGroupcreatorID(grouplist?.groupID?.creatorUserID)
  //         setSelectedGroupSideBar(grouplist?.groupID?._id)
  //         dispatch(updatconversationwithgroupAction({
  //           groupid: grouplist?.groupID?._id
  //         }))
  //         setGroupName(grouplist?.groupID?.groupName)
  //       }
  //     } else {
  //       setSelectedGroup(localStorage.getItem('id'))
  //       setSelectedGroupSideBar(localStorage.getItem('id'))
  //       setGroupcreatorID(localStorage.getItem('creator'))
  //       setGroupName(localStorage.getItem('gname'))
  //       dispatch(updatconversationwithgroupAction({
  //         groupid: localStorage.getItem('id')
  //       }))
  //     }
  //     setgrouplistArr(data?.payload)
  //   });
  // }, [])

  useEffect(() => {
    if (search == "") {
      var filteredItems = grouplistarray;
    } else {
      var filteredItems = grouplistArr.filter((item) => regex.test(item?.groupID?.groupName));
    }
    setgrouplistArr(filteredItems);
  }, [search]);

  useEffect(() => {
    dispatch(getGrouplistAction()).then(function (data) {
      const grouplist = data?.payload[0];
      if (currGroupId) {
        let roomDetail = data?.payload?.find((item) => item?.groupID?._id?.toString() == currGroupId);
        setSelectedGroup(roomDetail?.groupID?._id)
        setGroupcreatorID(roomDetail?.groupID?.creatorUserID)
        setGroupName(roomDetail?.groupID?.groupName)
        setSelectedGroupSideBar(roomDetail?.groupID?._id)
        if (selectedGroupSideBar !== roomDetail?.groupID?._id) {
          dispatch(updatconversationwithgroupAction({
            groupid: roomDetail?.groupID?._id
          }))
        }
        setFiles([])
        setArrFiles([])
        if (location.pathname == "/new-tawk" || location.pathname.startsWith("/edit-tawk/")) { navigate('/tawks') }
      }
      else {
        setSelectedGroup(grouplist?.groupID?._id)
        setGroupcreatorID(grouplist?.groupID?.creatorUserID)
        setSelectedGroupSideBar(grouplist?.groupID?._id)
        dispatch(updatconversationwithgroupAction({
          groupid: grouplist?.groupID?._id
        }))
        setGroupName(grouplist?.groupID?.groupName)
      }
      setgrouplistArr(data?.payload)
    });
  }, [currGroupId])

  return (
    <div className="full-sidebar-main">
      <div className="sidebar-search-cmn">
        <h3 className="cmn-hdng-14 pb-2">Typetawk Tawk</h3>
        <Form className="search-form position-relative" onSubmit={(e) => e.preventDefault()}>
          <Form.Group>
            <Form.Control
              type="search"
              placeholder="Search"
              id="serachGroup"
              onChange={(e) => setSearch(e.target.value)}
            />
            <img src={searchicon} alt="" className="search-ico" />
          </Form.Group>
          <NavLink to="/new-tawk" className="group-name-click">
            <button type="button">
              <img src={require("../../Assets/images/groupadd.svg").default} />
            </button>
          </NavLink>
        </Form>
      </div>

      <div className="user-click-tab">
        <h4 className="h-12 fav-heading pt-2">Favourite</h4>
        <div className="side-lists">
          {(grouplistFilteredarray || grouplistArr)?.map((item, index) => {
            if (item?.groupID != null) {
              return (
                <>
                  <div className={((selectedgroup || selectedGroupSideBar) == item?.groupID?._id) ? "collab-names activechatwo" : "collab-names"}
                    style={{ cursor: 'pointer' }}
                    key={item?.groupID?._id}
                    onClick={() => {
                      // if (isLoading) return
                      navigate(`/tawks?id=${item?.groupID?._id}`)
                      setIsLoading(true)
                      // setSelectedGroup(item?.groupID?._id)
                      // setGroupcreatorID(item?.groupID?.creatorUserID)
                      // setGroupName(item?.groupID?.groupName)
                      // setSelectedGroupSideBar(item?.groupID?._id)
                      // localStorage.setItem('id', item?.groupID?._id)
                      // localStorage.setItem('gname', item?.groupID?.groupName)
                      // localStorage.setItem('creator', item?.groupID?.creatorUserID)
                      // if (selectedGroupSideBar !== item?.groupID?._id) {
                      //   dispatch(updatconversationwithgroupAction({
                      //     groupid: item?.groupID?._id
                      //   }))
                      // }
                      // setFiles([])
                      // setArrFiles([])
                      // if (location.pathname == "/new-tawk" || location.pathname.startsWith("/edit-tawk/")) { navigate('/tawks') }
                    }}>
                    <div className="group-name-click with-img-avt">
                      <span>
                        <InitialsAvatar name={item?.groupID?.groupName?.replace(/[^\w\s]/gi, '') || "Typetawk User"} />
                        <h2 className="cmn-hdng-14" >{item?.groupID?.groupName}</h2>
                      </span>
                      <div className="pro-name-text">
                        {item?.totalunreadmessage > 0 &&
                          <div className="chatcount">{item?.totalunreadmessage < 99 ? item?.totalunreadmessage : "99+"}   </div>}
                      </div>
                    </div>
                  </div>
                </>
              )
            }
          })
          }
        </div>
      </div>
    </div>
  );
}
