import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import InitialsAvatar from "react-initials-avatar";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { useSelector } from "react-redux";
import { selectUserDetail } from "../redux/reducers/authReducer";
import { useDispatch } from "react-redux";
import { updateProfile, uploadFileAction } from "../redux/actions/authAction";
import MainLayout from "../components/Layout/MainLayout";
import ChatImageComponent from "../components/ImgComponent/ChatImageComponent";
import SEO from "../components/SEO";

export default function Account() {
  var userDetail = useSelector(selectUserDetail);
  const [newFullName, setNewFullName] = useState(userDetail?.name);
  const dispatch = useDispatch();
  // const [selectedImage, setSelectedImage] = useState(userDetail?.profile_pic);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);

  const roleMappings = {
    0: "User",
    1: "Guest",
    2: "Manager",
    3: "Admin",
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setFileToUpload(file)
    // const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes

    // if (fileSizeMB <= 1.0) {
    //   // Function to compress the image
    //   const compressImage = (image, quality) => {
    //     return new Promise((resolve) => {
    //       const canvas = document.createElement("canvas");
    //       const ctx = canvas.getContext("2d");
    //       const img = new Image();

    //       img.onload = () => {
    //         canvas.width = img.width;
    //         canvas.height = img.height;

    //         ctx.drawImage(img, 0, 0, img.width, img.height);

    //         canvas.toBlob(
    //           (blob) => {
    //             resolve(blob);
    //           },
    //           "image/jpeg",
    //           quality
    //         );
    //       };

    //       img.src = image;
    //     });
    //   };

    //   setFileToUpload(file);

    //   // Check if a file is selected and it's an image
    //   if (file && file.type.startsWith("image/")) {
    //     const reader = new FileReader();

    //     reader.onload = async () => {
    //       // Compress the image with 0.8 quality (adjust as needed)
    //       const compressedBlob = await compressImage(reader.result, 0.1);

    //       // Set the compressed image as a File
    //       const compressedFile = new File([compressedBlob], file.name, {
    //         type: "image/jpeg",
    //       });

    //       setFileToUpload(compressedFile);
    //       setSelectedImage(reader.result);
    //     };

    //     // Read the selected image as Data URL
    //     reader.readAsDataURL(file);
    //   } else {
    //     // Reset the selected image if the selected file is not an image
    //     setSelectedImage(null);
    //   }
    // } else {
    //   toast.error("File size must be less than 1MB");
    // }
  };

  const handleFullNameChange = (e) => {
    if (e.target.value != null) {
      setNewFullName(e.target.value);
    }
  };
  const uploadFile = async (filename) => {
    try {
      let formData = new FormData();
      formData.append('file', filename);
      const res = await dispatch(uploadFileAction(formData));
      let url = res.payload.data.url;
      return url;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }

  const handleUpdateClick = async () => {
    setIsUpdatingProfile(true);
    let payload = {
      name: newFullName,
      _id: userDetail?._id,
    }
    try {
      if (fileToUpload != null) {
        const res = await uploadFile(fileToUpload)
        payload['profile_pic'] = res
      }
      dispatch(updateProfile(payload))
    } catch (error) {
    }
    finally {
      setIsUpdatingProfile(false)
    }
  };


  return (
    <>
      <SEO
        title="Team Communication Software | Typetawk"
        description='Elevate your Typetawk experience by updating your profile with ease.'
        og_des='Chatting APP'
        og_title='Typetawk - Update Profile'
      />
      <MainLayout>
        <Container fluid className="px-0">
          {/* typetawk chat box start */}
          <div className="form-box-group-main">
            <Container>
              <Form className="group-form">
                <Row>
                  <Col lg="12" className="d-flex justify-content-center">
                    <div className="image-upload">
                      {(fileToUpload || userDetail?.profile_pic) ? (
                        // <img
                        //   className="rounded-image show-img"
                        //   src={fileToUpload}
                        //   alt="Selected Image"
                        //   onError={CommonFiles.onImageError}
                        // />
                        <ChatImageComponent imageUrl={fileToUpload ? URL.createObjectURL(fileToUpload) : userDetail?.profile_pic} file={!fileToUpload} type="user" className="rounded-image show-img" />
                      ) :
                        (
                          <InitialsAvatar
                            name={userDetail?.name || "Typetawk"}
                            className="rendom-img"
                          />
                        )
                      }

                      <button>
                        <img
                          src={
                            require("../Assets/images/cameraicon.svg").default
                          }
                        />
                        <input
                          className="form-control"
                          type="file"
                          id="formFile"
                          onChange={handleFileChange}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    lg="4"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Form.Label>Full Name</Form.Label>
                  </Col>
                  <Col lg="8">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="fullname"
                        value={newFullName}
                        onChange={handleFullNameChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    lg="4"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Form.Label>Username (Typetawk ID)</Form.Label>
                  </Col>
                  <Col lg="8">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="typetawkid"
                        value={userDetail?.username}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    lg="4"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Form.Label>Email</Form.Label>
                  </Col>
                  <Col lg="8">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="manpreet@codobux.com"
                        name="email"
                        value={userDetail?.email}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    lg="4"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Form.Label>Role</Form.Label>
                  </Col>
                  <Col lg="8">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Administrator"
                        name="role"
                        value={roleMappings[userDetail?.role]}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    lg="4"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Form.Label>Appearance</Form.Label>
                  </Col>
                  <Col lg="8">
                    <Form.Select
                      aria-label="Default select example "
                      className=""
                      disabled
                    >
                      <option>Light</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
          {/* typetawk chat box end */}

          {/* typetawk message bar start*/}

          <div className="group-bottom-btns">
            <div className="group-btns-inner">
              <button
                id="updateButton"
                className="create-group"
                onClick={() => {
                  handleUpdateClick();
                }}
                disabled={isUpdatingProfile} // Disable the button based on the state
              >
                {isUpdatingProfile ? "Updating..." : "Update"}
              </button>
            </div>
          </div>
          {/* typetawk message bar end*/}
        </Container>
      </MainLayout>
    </>
  );
}
